const JobDetails = ({ jobcard }) => {
  return (
    <>
      <br />

      <h3 className="jobCardView-title">Vehicle Details</h3>
      <div className="jobCardView-details">
        <div className="jobCardView-detail">
          <p>Vehicle Number</p>
          <p>{jobcard?.vehicleNo}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Brand</p>
          <p>{jobcard?.brand}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Model</p>
          <p>{jobcard?.model}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Year</p>
          <p>{jobcard?.year}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Engine Number</p>
          <p>{jobcard?.eng_no}</p>
        </div>
      </div>

      <br />

      <h3 className="jobCardView-title">Customer Details</h3>
      <div className="jobCardView-details">
        <div className="jobCardView-detail">
          <p>Name</p>
          <p>{jobcard?.customer_name}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Contact</p>
          <p>
            {jobcard?.phone}
            <a
              href={`tel:${jobcard?.phone}`}
              style={{ color: "#FC5C2E", marginLeft: 10 }}
            >
              CALL
            </a>
          </p>
        </div>
        <div className="jobCardView-detail">
          <p>Email</p>
          <p>{jobcard?.email}</p>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
