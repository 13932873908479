import "./index.scss";
import React, { useEffect } from "react";
import { useEmployeeStore } from "../../../Stores/Employee";
import Moment from "react-moment";
import routes from "../../../Common/routes";
import { Link } from "react-router-dom";
import AppBar from "../../../Common/UI/AppBar";

const Feedback = (props) => {
  const [{ listData }, { listpunch }] = useEmployeeStore();

  useEffect(() => {
    const { employee_id } = props?.match?.params;
    listpunch(employee_id);
  }, [listpunch, props?.match?.params]);
  return (
    <div className="autoLogs">
      <AppBar onBack={() => window.history.back()}>
        <div className="autoAppbar-title">
          <h3>{listData !== null && listData[0]?.employee_name}</h3>
        </div>
      </AppBar>
      <div style={{ padding: "20px" }}>
        {listData?.map((res, index) => (
          <Link
            to={{
              pathname: routes.VIEW_MAP,
              param1: index,
            }}
          >
            <div className="auto_log_cover">
              <span className="auto_date">
                <h3>
                  <Moment format="YYYY/MM/DD">{res.punch_in_date}</Moment>
                </h3>
                <span>
                  <h6>
                    Sign in&nbsp;
                    <Moment format="hh:mm">{res.punch_in_date}</Moment>{" "}
                    &nbsp;*&nbsp;
                  </h6>
                  *
                  {res?.punch_out_date !== null && (
                    <h6>
                      Sign out &nbsp;
                      <Moment format="hh:mm">{res.punch_out_date}</Moment>
                    </h6>
                  )}
                </span>
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Feedback;
