import "./index.scss";

import FeatherIcon from "feather-icons-react";

const VendorStackCard = () => {
  return (
    <div className="vendorStackCard">
      <div className="cardCol1">Go to</div>
      <div className="cardCol2">Vendor List</div>
      <div className="cardCol3">
        <FeatherIcon icon="arrow-right" className="stackCardIcon" />
      </div>
    </div>
  );
};

export default VendorStackCard;
