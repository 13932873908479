import "./index.scss";

import { Menu } from "antd";
import { useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";
import { useEffect } from "react";
import config from "../../../../common/config";
import DetailCard from "../../../Common/UI/DetailCard";
import AddStock from "../Form/AddStock";
import Modal from "antd/lib/modal/Modal";

const InventoryView = ({ match }) => {
  const history = useHistory();
  const [
    { loadingInventory, inventory, viewAddStockModal },
    { handleGetInventory, setViewAddStockModal },
  ] = useInventoryStore();

  useEffect(() => {
    handleGetInventory(match?.params?.inventoryId);
  }, [handleGetInventory, match]);

  return (
    <div className="inventoryViewContainer">
      <AppBar
        onBack={() => history.push(routes.LIST_INVENTORY)}
        dropDownMenu={
          <Menu>
            <Menu.Item key="add" onClick={() => setViewAddStockModal(true)}>
              Add Stock
            </Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>{loadingInventory ? "Loading" : inventory?.product}</h3>
        </div>
      </AppBar>
      {loadingInventory ? (
        <Loader tip="Loading inventory..." />
      ) : (
        <div className="inventoryView">
          <DetailCard
            type="box"
            img={config.BASE_API + inventory?.image}
            name={inventory?.product}
            desc={inventory?.discription}
            details={{
              Store: inventory?.store,
              "Store Address": inventory?.store_address,
              Product: inventory?.product,
              Price: inventory?.price,
              SGST: inventory?.cgst,
              IGST: inventory?.igst,
              Stock: inventory?.stock,
            }}
          />
          {/* <div>
            <button className="inventoryView-orderBtn">Order Item</button>
          </div> */}
        </div>
      )}
      <Modal
        centered
        visible={viewAddStockModal}
        onCancel={() => setViewAddStockModal(false)}
        footer={null}
        title="Add Stock"
      >
        <AddStock
          id={match?.params?.inventoryId}
          varient={inventory.product_variant}
        />
      </Modal>
    </div>
  );
};

export default InventoryView;
