import { Menu, Tabs } from "antd";

import JobAppBar from "../Common/JobAppBar";
import { useEffect } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import Loader from "../../../Common/UI/Loader";
import JobListCard from "../Common/JobListCard";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import { getJobcardStatus } from "../View/Common/getJobcardStatus";
import "./index.scss";

const { TabPane } = Tabs;

const jobcardStatus = {
  pending: { label: "Waiting to Accept", color: "clr-warn" },
  accepted: { label: "Collected by employees", color: "clr-warn" },
  ongoing: { label: "Ongoing", color: "clr-msg" },
  completed: { label: "Completed", color: "clr-msg" },
  delivered: { label: "Delivered", color: "clr-msg" },
  rejected: { label: "Declined", color: "clr-danger" },
};

const JobCardList = () => {
  const [{ jobcardList, loadingCardList }, { handleGetAllJobcards }] =
    useJobcardStore();

  const history = useHistory();

  useEffect(() => {
    handleGetAllJobcards();
  }, [handleGetAllJobcards]);
  console.log("Status", jobcardStatus[getJobcardStatus(jobcardList[3])]?.label);
  console.log("JobCard", jobcardList);

  // const newJobStatus = ["pending", "accepted"];
  // const ongoingJobStatus = [
  //   "collected-by-employee",
  //   "ongoing",
  //   "completed-by-employee",
  // ];
  // const deliveredJobStatus = ["delivered"];

  return (
    <div className="jobCardListContainer">
      <JobAppBar
        onBack={() => history.push(routes.HOME)}
        title="JobCards"
        dropDownMenu={<Menu></Menu>}
      />
      <Link to={routes.MANUAL_CARD}>
        <button className="manual_jobcard">Create Job Card</button>
      </Link>
      {loadingCardList ? (
        <Loader tip="Loading jobcards" />
      ) : (
        <div className="jobCardList">
          <Tabs>
            <TabPane tab="New" key="new" className="jobListCards">
              {jobcardList
                ?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Waiting to Accept" || "Collected by employees"
                  )
                )
                ?.map((jobcard) => (
                  <JobListCard key={jobcard?.id} jobcard={jobcard} />
                ))}
            </TabPane>
            <TabPane tab="Ongoing" key="ongoing" className="jobListCards">
              {jobcardList
                ?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Ongoing"
                  )
                )
                ?.map((jobcard) => (
                  <JobListCard key={jobcard?.id} jobcard={jobcard} />
                ))}
            </TabPane>
            <TabPane tab="Completed" key="completed" className="jobListCards">
              {jobcardList
                ?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Completed"
                  )
                )
                ?.map((jobcard) => (
                  <JobListCard key={jobcard?.id} jobcard={jobcard} />
                ))}
            </TabPane>
            <TabPane tab="Delivered" key="delivered" className="jobListCards">
              {jobcardList
                ?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Delivered"
                  )
                )
                ?.map((jobcard) => (
                  <JobListCard key={jobcard?.id} jobcard={jobcard} />
                ))}
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default JobCardList;
