import "./index.scss";

import { Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";

import { logoWithTitle } from "../../Common/Assets";
import { useAuthStore } from "../../Stores/Auth";

const Login = () => {
  const history = useHistory();
  const [{ loading }, { login }] = useAuthStore();

  return (
    <div className="loginContainer">
      <div className="loginBanner">
        <img src={logoWithTitle} alt="logo" />
      </div>
      <div className="loginSpace"></div>
      <div className="loginPortal">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(values) => login(values, history)}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <br />
          <Form.Item>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              htmlType="submit"
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
