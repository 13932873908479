import React from "react";
import { Column } from "@ant-design/plots";

const index = () => {
  const data = [
    {
      type: "M",
      sales: 38,
    },
    {
      type: "Tu",
      sales: 52,
    },
    {
      type: "W",
      sales:45,
    },
    {
      type: "Th",
      sales: 145,
    },
    {
      type: "F",
      sales: 48,
    },
    {
      type: "S",
      sales: 38,
    },

   
  ];
  const config = {
    data,
    xField: "type",
    yField: "sales",
    color:"#FF6F00",
    columnWidthRatio:0.6,
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "data",
      },
      sales: {
        alias: "Services",
      },
    },
  };
  return <Column {...config} />;
};

export default index;
