import "./index.scss";

import { Avatar, Menu } from "antd";
import { useHistory } from "react-router";

import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import VendorForm from "../Form";
import { useEffect, useState } from "react";
import { useVendorStore } from "../../../Stores/Vendor";
import Loader from "../../../Common/UI/Loader";

const VendorEdit = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [{ currentVendor }, { getVendor, setCurrentVendor }] = useVendorStore();

  useEffect(() => {
    const { vendorId } = props?.match?.params;
    // See if the data of the current employee is present in the local state
    if (currentVendor && currentVendor?.id === vendorId) {
      setLoading(false);
    } else {
      // Set the currentEmployee object to null initially to clear data if already present.
      setCurrentVendor(null);
      if (vendorId) getVendor(vendorId, setLoading);
    }
  }, [currentVendor, getVendor, props?.match?.params, setCurrentVendor]);

  if (loading) return <Loader tip="Loading employee details..." />;

  return (
    <div className="vendorEdit">
      <AppBar
        onBack={() => history.push(routes.LIST_VENDOR("123"))}
        dropDownMenu={
          <Menu>
            <Menu.Item key="edit">Edit</Menu.Item>
            <Menu.Item key="delete">Delete</Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Edit</h3>
        </div>
      </AppBar>
      <div className="editContent">
        <div className="editAvatarContainer">
          <Avatar size={100} />
        </div>
        <VendorForm {...{ currentVendor, purpose: "edit" }} />
      </div>
    </div>
  );
};

export default VendorEdit;
