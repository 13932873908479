import { Button, Form, Modal } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import AddProductModal from "./AddProductModal";
import AssignModal from "./AssignModal";

const ServiceCard = ({ service, id, isCompleted }) => {
  const [
    { viewAssignModal, addProductModal },
    { setViewAssignModal, setAddProductModal },
  ] = useJobcardStore();
  const [bayEmpForm] = Form.useForm();
  const [productForm] = Form.useForm();
  return (
    <div className="jobCardView-job">
      <p>JOB ID {id}</p>
      <h3>{service?.service_name}</h3>
      {isCompleted ? (
        <Button
          type="primary"
          style={{ width: "100%", marginTop: 15 }}
          className="jobCard-btn"
          disabled
        >
          Completed
        </Button>
      ) : (
        <>
          <Button
            onClick={() => setViewAssignModal(service?.id)}
            type="primary"
            className="jobCard-btn"
            style={{ width: "100%", marginTop: 15 }}
          >
            {service?.employee !== null && service?.bay !== null && "Re-"}Assign
            Bay and Employee
          </Button>
          <Button
            onClick={() => setAddProductModal(service?.id)}
            type="primary"
            className="jobCard-btn"
            style={{ width: "100%", marginTop: 15 }}
          >
            Assign Product
          </Button>
          <Modal
            centered
            visible={viewAssignModal === service?.id}
            title="Assign Bay & Employee"
            footer={null}
            onCancel={() => {
              bayEmpForm.resetFields();
              setViewAssignModal(null);
            }}
          >
            <AssignModal form={bayEmpForm} service={service} />
          </Modal>
          <Modal
            centered
            visible={addProductModal === service?.id}
            title={`Assign Product to ${service?.service_name}`}
            footer={null}
            onCancel={() => {
              productForm.resetFields();
              setAddProductModal(null);
            }}
          >
            <AddProductModal form={productForm} id={service?.id} />
          </Modal>
        </>
      )}
      <p style={{ paddingTop: "8px" }}>
        {" "}
        Assigned Employee&nbsp; - &nbsp;
        {service?.employee !== null &&
          service?.bay !== null &&
          service?.employee_name}
      </p>
    </div>
  );
};

export default ServiceCard;
