const JobInvoice = ({ jobcard }) => {
  if (jobcard?.services?.length < 1) return <></>;
  return (
    <div className="jobCardView-invoices">
      <br />
      <h3 className="jobCardView-title">Invoice Details</h3>
      <p className="jobCardView-invoices-hd">Order#</p>
      <h3 className="jobCardView-invoices-id">{jobcard?.reg_no}</h3>
      <br />
      {jobcard?.services?.map((service, index) => (
        <div key={index} className="jobCardView-invoice">
          <p>{index + 1}.</p>
          <p>
            {service?.service_name}
            <br />
            <span>{`${jobcard?.reg_no}_${index}`}</span>
          </p>
          <p>{service?.price}</p>
        </div>
      ))}
      <div className="jobCardView-invoice-divider"></div>
      <div className="jobCardView-invoice">
        <p></p>
        <p>TOTAL</p>
        <p>
          {isNaN(
            jobcard?.services
              ?.map((service) => service?.price)
              ?.reduce((a, b) => a + b)
          )
            ? 0
            : jobcard?.services
                ?.map((service) => service?.price)
                ?.reduce((a, b) => a + b)}
        </p>
      </div>
    </div>
  );
};

export default JobInvoice;
