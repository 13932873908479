import { Avatar } from "antd";
import config from "../../../../../common/config";
import "./index.scss";

const ServiceExpandedCard = ({ service_id, category_name, name, image }) => {
  return (
    <div className="serviceExpandedCard">
      <div className="imgContainer">
        <Avatar size={65} src={config.BASE_API + image} />
      </div>
      <div className="content">
        <div className="serviceDetails">
          <h2>{category_name}</h2>
        </div>
        <div className="stockDetails">{service_id}</div>
      </div>
    </div>
  );
};

export default ServiceExpandedCard;
