import "./index.scss";

import { Link, useHistory } from "react-router-dom";

import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import { useVendorStore } from "../../../Stores/Vendor";
import { useEffect } from "react";
import Loader from "../../../Common/UI/Loader";
import ListCard from "../../../Common/UI/ListCard";
import ListCollection from "../../../Common/UI/ListCollection";

const VendorList = () => {
  const history = useHistory();
  const [{ vendorsList, loading }, { getVendorList }] = useVendorStore();

  useEffect(() => {
    getVendorList();
  }, [getVendorList]);

  if (loading) return <Loader tip="Getting employee data..." />;

  return (
    <div className="vendorList">
      <AppBar onBack={() => history.push(routes.VENDOR)}>
        <div className="autoAppbar-title">
          <h3>Vendor Management</h3>
        </div>
      </AppBar>
      <ListCollection>
        {vendorsList?.map((vendor, key) => (
          <Link key={key} to={routes.EXPANDED_VENDOR(vendor?.id)}>
            <ListCard body={[vendor?.name, vendor?.phone, vendor?.vendor_id]} />
          </Link>
        ))}
      </ListCollection>
    </div>
  );
};

export default VendorList;
