import { get, patch, post } from "../common/remote";

export const getVendorList = () => {
  return get(`/vendors/view-vendors/`);
};

export const addVendor = (data) => {
  return post("/vendors/add-vendor/", data);
};

export const deleteVendor = (id) => {
  return post(`/vendors/delete-vendor/${id}`);
};

export const editVendor = (newData, id) => {
  return patch(`/vendors/edit-vendor/${id}`, newData);
};
