import "../index.scss";

import { Menu, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import { FeatherIcon } from "../../../Common/Assets";
import AppBar from "../../../Common/UI/AppBar";
import { useEffect } from "react";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";
import AddForm from "../Form/AddForm";
import ListCard from "../../../Common/UI/ListCard";
import config from "../../../../common/config";
import ListCollection from "../../../Common/UI/ListCollection";

const InventoryList = () => {
  const history = useHistory();
  const [
    { loadingInventoryList, inventoryList, viewAddModal },
    { handleGetInventoryList, setViewAddModal, setInventory },
  ] = useInventoryStore();

  useEffect(() => {
    handleGetInventoryList();
  }, [handleGetInventoryList]);

  return (
    <div className="inventoryListContainer">
      <AppBar
        onBack={() => history.push(routes.HOME)}
        dropDownMenu={
          <Menu>
            <Link to={routes.CREATE_INVENTORY}>
              <Menu.Item key="add">Add</Menu.Item>
            </Link>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Products</h3>
          <FeatherIcon icon="search" />
        </div>
      </AppBar>
      <ListCollection>
        {loadingInventoryList ? (
          <Loader tip="Loading inventories..." />
        ) : (
          inventoryList.map((item) => (
            <div
              key={item?.id}
              onClick={() => {
                setInventory(item);
                history.push(routes.VIEW_INVENTORY(item?.id));
              }}
            >
              <ListCard
                body={[
                  item?.product,
                  item?.discription,
                  <>
                    <b>&#8377;</b> {item?.price}
                    <div>Stock: {item?.stock}</div>
                  </>,
                ]}
                img={config.BASE_API + item?.image}
              />
            </div>
          ))
        )}
        <Modal
          centered
          visible={viewAddModal}
          onCancel={() => setViewAddModal(false)}
          footer={null}
          title="Add Inventory"
        >
          <AddForm />
        </Modal>
      </ListCollection>
    </div>
  );
};

export default InventoryList;
