import "./index.scss";

import { Button, DatePicker, Form, Input, InputNumber } from "antd";
import { useEmployeeStore } from "../../../Stores/Employee";
import { useState } from "react";
import getMoment from "../../../Common/Utils/getMoment";
import { useHistory } from "react-router";

const EmployeeForm = ({ currentEmployee, purpose }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [, { editEmployee, createEmployee }] = useEmployeeStore();

  return (
    <div className="employeeForm">
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={(data) => {
          setLoading(true);
          purpose === "edit"
            ? editEmployee(data, setLoading, currentEmployee?.id, history)
            : createEmployee(data, setLoading, history);
        }}
      >
        {/* Item Name */}
        <Form.Item
          name="name"
          label="Employee Name"
          initialValue={currentEmployee && currentEmployee?.name}
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input placeholder="Enter the name" />
        </Form.Item>

        {/* DOB */}
        <Form.Item
          name="dob"
          label="DOB"
          initialValue={currentEmployee && getMoment(currentEmployee?.dob)}
          rules={[{ required: true, message: "Date of Birth is required" }]}
        >
          <DatePicker />
        </Form.Item>

        {/* Email */}
        <Form.Item
          name="email"
          label="Email"
          initialValue={currentEmployee && currentEmployee?.email}
          rules={[
            { type: "email", required: true, message: "Email is required" },
          ]}
        >
          <Input placeholder="Enter the email" />
        </Form.Item>

        {/* Address */}
        <Form.Item
          name="address"
          label="Address"
          initialValue={currentEmployee && currentEmployee?.address}
          rules={[
            {
              required: true,
              message: "Address is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Enter the address" />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item
          name="phone"
          label="Phone Number"
          initialValue={currentEmployee && currentEmployee?.phone}
          rules={[
            {
              required: true,
              message: "Phone number is required",
            },
          ]}
        >
          <InputNumber placeholder="Enter the phone number" />
        </Form.Item>

        {/* Bank ACC Number */}
        <Form.Item
          name="bank_acc"
          label="Bank Account Number"
          initialValue={currentEmployee && currentEmployee?.bank_acc}
          rules={[
            {
              required: true,
              message: "Bank account number is required",
            },
          ]}
        >
          <InputNumber placeholder="Enter the bank account number" />
        </Form.Item>

        {/* Bank IFSC */}
        <Form.Item
          name="ifsc"
          label="Bank IFSC"
          initialValue={currentEmployee && currentEmployee?.ifsc}
          rules={[
            {
              required: true,
              message: "Bank IFSC is required",
            },
          ]}
        >
          <Input placeholder="Enter the bank IFSC code" />
        </Form.Item>

        <br />
        <Form.Item className="employeeForm-submit">
          <Button
            type="primary"
            style={{ width: "100%" }}
            loading={loading}
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmployeeForm;
