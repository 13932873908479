import "./index.scss";

import { Empty, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useEmployeeStore } from "../../../Stores/Employee";
import { useEffect, useState } from "react";
import Loader from "../../../Common/UI/Loader";
import DetailCard from "../../../Common/UI/DetailCard";

const EmployeeExpanded = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [
    { currentEmployee },
    { getEmployee, setCurrentEmployee, deleteEmployee },
  ] = useEmployeeStore();

  useEffect(() => {
    // Set the currentEmployee object to null initially to clear data if already present.
    setCurrentEmployee(null);
    setLoading(true);
    const { employeeId } = props?.match?.params;
    if (employeeId) getEmployee(employeeId, setLoading);
  }, [getEmployee, props?.match?.params, setCurrentEmployee, setLoading]);

  if (loading) return <Loader tip="Loading employee details..." />;

  return (
    <div className="employeeExpanded">
      <AppBar
        onBack={() => history.push(routes.LIST_EMPLOYEE("random"))}
        dropDownMenu={
          <Menu>
            <Link to={routes.EDIT_EMPLOYEE(props?.match?.params?.employeeId)}>
              <Menu.Item key="edit">Edit</Menu.Item>
            </Link>
            <Menu.Item
              onClick={() =>
                deleteEmployee(props?.match?.params?.employeeId, history)
              }
              key="delete"
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>{currentEmployee?.name}</h3>
        </div>
      </AppBar>
      {currentEmployee === null ? (
        <Empty description="No data" />
      ) : (
        <DetailCard
          type="circle"
          img=""
          name={currentEmployee?.name}
          desc={currentEmployee?.username}
          details={{
            Name: currentEmployee?.name,
            Phone: currentEmployee?.phone,
            Email: currentEmployee?.email,
            Address: currentEmployee?.address,
            DOB: currentEmployee?.dob,
            "Account No": currentEmployee?.bank_acc,
            "IFSC Code": currentEmployee?.ifsc,
          }}
        />
      )}
      <Link key={currentEmployee?.id} to={routes.VIEW_ATTENDANCE(currentEmployee?.id)}>
        <h3 style={{ color: "rgb(243, 97, 0)", marginLeft: "20px" }}>
          Punch in details
        </h3>
      </Link>
    </div>
  );
};

export default EmployeeExpanded;
