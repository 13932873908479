const initialState = {
  loadingCardList: true,
  jobcardList: [],
  loadingJobcard: true,
  jobcard: null,
  reportList: null,
  viewReportModal: null,
  loadingReportModal: true,
  viewAssignModal: null,
  addProductModal: null,
  loadingAssignModal: true,
  assignBayList: [],
  assignEmployeeList: [],
  productList: [],
  isAssigning: false,
  isAddingProduct: false,
  isUpdatingStatus: false,
  isSelling: false,
  status: null,
  verfiedUser: null,
  userVehicles: null,
  userBrands: null,
  brandModal: false,
  cart: null,
};

export default initialState;
