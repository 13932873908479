import { get } from "../common/remote";

export const getStockIncomeReport = () => {
  return get(`reports/store-products-stock-view/`);
};

export const getServiceIncomeReport = () => {
  return get(`reports/store-service-incomereport/`);
};

export const getProductIncomeReport = () => {
  return get(`reports/store-products-incomereport/`);
};

export const getStockIncomeReportTimeline = (startDate, endDate) => {
  return get(
    `reports/store-products-stock-view/?start_date=${startDate}&end_date=${endDate}`
  );
};

export const getServiceIncomeReportTimeline = (startDate, endDate) => {
  return get(
    `reports/store-service-incomereport?start_date=${startDate}&end_date=${endDate}`
  );
};

export const getProductIncomeReportTimeline = (startDate, endDate) => {
  return get(
    `reports/store-products-incomereport/?start_date=${startDate}&end_date=${endDate}`
  );
};
