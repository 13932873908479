import "./index.scss";
import "../index.scss";

import moment from "moment";

import JobAppBar from "../Common/JobAppBar";
// import jobcard from "./jobcard.json";
import ServiceCard from "./Common/ServiceCard";
import JobDetails from "./Common/JobDetails";
// import JobInvoice from "./Common/JobInvoice";
import JobFooter from "./Common/JobFooter";
import { useEffect } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import Loader from "../../../Common/UI/Loader";
import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import JobProducts from "./Common/JobProducts";
import JobInvoice from "./Common/JobInvoice";
import { Button } from "antd";
import ReportTable from "./Common/ReportTable";

const JobCardView = ({ match }) => {
  const [
    { loadingJobcard, jobcard, viewReportModal },
    { handleGetJobcard, setViewReportModal },
  ] = useJobcardStore();
  const history = useHistory();

  const completedStatus = [
    "collected-by-employee",
    "ongoing",
    "completed-by-employee",
    "delivered",
  ];

  useEffect(() => {
    handleGetJobcard(match.params.jobId);
  }, [match, handleGetJobcard]);

  console.log(jobcard, "jobcard data");

  return (
    <div className="jobCardViewContainer">
      <JobAppBar
        onBack={() => history.push(routes.LIST_JOB_CARD)}
        title="Dashboard"
      />
      {loadingJobcard ? (
        <Loader tip="Loading job card" />
      ) : (
        <div className="jobCardView">
          {jobcard?.status === "delivered" && (
            <div className="show-report-con">
              <Button
                type="primary"
                style={{ width: "100%", margin: "15px 0" }}
                className="jobCard-btn"
                // onClick={() => setViewReportModal(!viewReportModal)}
                onClick={() =>
                  history.push(routes.VIEW_JOB_CARD_REPORT(match.params.jobId))
                }
              >
                {/* {viewReportModal ? "Hide" : "View"} Report */}
                View Report
              </Button>
              {/* <div className="report-sec">
                {viewReportModal ? <ReportTable /> : ""}
              </div> */}
            </div>
          )}
          <h3 className="jobCardView-title">Service Details</h3>
          <div className="jobCardView-header">
            <h3>JOB ID {jobcard?.reg_no}</h3>
            <p>
              <span>Job created at </span>
              <br />
              {moment(jobcard?.date_added, "YYYY-MM-DD HH:mm").format(
                "DD.MM.YYYY HH:mm"
              )}
            </p>
          </div>
          <div className="jobCardView-jobs">
            {jobcard?.services?.map((service, index) => (
              <ServiceCard
                key={service?.id}
                id={`${jobcard?.reg_no}_${index}`}
                service={service}
                isCompleted={completedStatus.includes(jobcard?.status)}
              />
            ))}
          </div>
          <JobDetails jobcard={jobcard} />
          <JobInvoice jobcard={jobcard} />
          <JobProducts products={jobcard?.products} />
          <JobFooter jobcard={jobcard} />
        </div>
      )}
      {/* <Modal
        centered
        visible={viewReportModal}
        title="REPORT"
        footer={null}
        onCancel={() => {
          setViewReportModal(null);
        }}
        style={{ width: "90%" }}
      >
        <ReportModal />
      </Modal> */}
    </div>
  );
};

export default JobCardView;
