import React, { useEffect, useState } from "react";
import "./stylesheet.scss";
import "./bootstrap.scss";
import { logo } from "../../../../../Common/Assets";
import { useParams } from "react-router-dom";
import { useJobcardStore } from "../../../../../Stores/Jobcard";
import moment from "moment";

const ReportInvoice = () => {
  const { jobId } = useParams();

  const [{ loadingReportModal, reportList }, { handleGetReport }] =
    useJobcardStore();

  let subTotal = 0;
  let tax = 0;
  let total = 0;

  useEffect(() => {
    handleGetReport(jobId);
  }, []);

  return (
    <div className="ReportInvoice">
      <div class="container-fluid invoice-container">
        <header>
          <div class="row align-items-center">
            <div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
              <img id="logo" src={logo} title="Koice" alt="Koice" />
            </div>
            <div class="col-sm-5 text-center text-sm-end">
              <h4 class="text-7 mb-0">Invoice</h4>
            </div>
          </div>
          <hr />
        </header>

        <main>
          <div class="row">
            <div class="col-sm-6">
              <strong>Date:</strong> {moment().format("DD/MM/YYYY")}
            </div>
            <div class="col-sm-6 text-sm-end">
              {" "}
              {/* <strong>Invoice No:</strong> 16835 */}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6 text-sm-end order-sm-1">
              {" "}
              <strong>Pay To:</strong>
              <address>
                MOTO 365
                <br />
                {reportList?.store_address}
                <br />
                contact@moto365.com
              </address>
            </div>
            <div class="col-sm-6 order-sm-0">
              {" "}
              <strong>Invoiced To:</strong>
              <address>
                {reportList?.customer}
                <br />
                {reportList?.customer_address?.address}
                <br />
                {reportList?.customer_address?.street}
                <br />
                {reportList?.services[0]?.vehicle_no ??
                  reportList?.customer_address?.postcode}
              </address>
            </div>
          </div>

          <div class="card">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-3">
                        <strong>Service</strong>
                      </td>
                      <td class="col-4">
                        <strong>Job ID</strong>
                      </td>
                      <td class="col-2 text-center">
                        <strong>Rate</strong>
                      </td>
                      <td class="col-1 text-center">
                        <strong>TAX</strong>
                      </td>
                      <td class="col-2 text-end">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList?.services?.map((r) => {
                      subTotal = subTotal + r?.price;
                      tax = tax + r?.igst;
                      total = total + r?.total_price;

                      return (
                        <tr key={r?.id}>
                          <td class="col-3">{r?.service_name}</td>
                          <td class="col-4 text-1">{r?.job_id}</td>
                          <td class="col-2 text-center">₹{r?.price}</td>
                          <td class="col-1 text-center">₹{r?.igst}</td>
                          <td class="col-2 text-end">₹{r?.total_price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot class="card-footer">
                    <tr>
                      <td colspan="4" class="text-end">
                        <strong>Sub Total:</strong>
                      </td>
                      <td class="text-end">₹{subTotal}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-end">
                        <strong>Tax:</strong>
                      </td>
                      <td class="text-end">₹{tax}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-end border-bottom-0">
                        <strong>Total:</strong>
                      </td>
                      <td class="text-end border-bottom-0">₹{total}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </main>
        <footer class="text-center mt-4">
          <p class="text-1">
            <strong>NOTE :</strong> This is computer generated receipt and does
            not require physical signature.
          </p>
          <div class="btn-group btn-group-sm d-print-none">
            <a
              href="javascript:window.print()"
              class="btn btn-light border text-black-50 shadow-none"
            >
              <i class="fa fa-print"></i> Print
            </a>
            {/* <a href="" class="btn btn-light border text-black-50 shadow-none">
              <i class="fa fa-download"></i> Download
            </a>{" "} */}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ReportInvoice;
