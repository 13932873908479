import "./index.scss";

import { Avatar } from "antd";
import { useHistory } from "react-router";

import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import VendorForm from "../Form";

const VendorCreate = () => {
  const history = useHistory();

  return (
    <div className="vendorCreate">
      <AppBar onBack={() => history.push(routes.LIST_VENDOR("123"))}>
        <div className="autoAppbar-title">
          <h3>Create</h3>
        </div>
      </AppBar>
      <div className="createContent">
        <div className="createAvatarContainer">
          <Avatar size={100} />
        </div>
        <VendorForm />
      </div>
    </div>
  );
};

export default VendorCreate;
