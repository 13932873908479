import { message } from "antd";
import { logError, msgError } from "../../../common/utils";
import {
  addBulkInventory,
  addInventory,
  listInventory,
  listVarients,
  viewInventory,
  addInventoryStock,
} from "../../../infrastructure/inventory";
import initialState from "./initialState";
import moment from "moment";

const actions = {
  setLoadingInventoryList:
    (loadingInventoryList) =>
    ({ setState }) => {
      setState({ loadingInventoryList });
    },

  setInventoryList:
    (inventoryList) =>
    ({ setState }) => {
      setState({ inventoryList });
    },

  setLoadingInventory:
    (loadingInventory) =>
    ({ setState }) => {
      setState({ loadingInventory });
    },

  setVarientList:
    (varientList) =>
    ({ setState }) => {
      setState({ varientList });
    },

  setLoadingVarients:
    (loadingVarients) =>
    ({ setState }) => {
      setState({ loadingVarients });
    },

  setInventory:
    (inventory) =>
    ({ setState }) => {
      setState({ inventory });
    },

  setViewBulkAddModal:
    (viewBulkAddModal) =>
    ({ setState }) => {
      setState({ viewBulkAddModal });
    },

  setViewAddModal:
    (viewAddModal) =>
    ({ setState }) => {
      setState({ viewAddModal });
    },
  setViewAddStockModal:
    (viewAddStockModal) =>
    ({ setState }) => {
      setState({ viewAddStockModal });
    },
  setIsAddingInventory:
    (isAddingInventory) =>
    ({ setState }) => {
      setState({ isAddingInventory });
    },

  handleGetVarientList:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingVarients(true));
      try {
        const res = await listVarients();
        dispatch(actions.setVarientList(res?.data));
      } catch (error) {
        logError(error, "handleGetVarientList()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingVarients(false));
      }
    },

  handleGetInventoryList:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingInventoryList(true));
      try {
        const response = await listInventory();
        dispatch(actions.setInventoryList(response.data));
      } catch (error) {
        logError(error, "handleGetInventoryList()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingInventoryList(false));
      }
    },

  handleGetInventory:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingInventory(true));
      try {
        const response = await viewInventory(id);
        dispatch(actions.setInventory(response.data));
        console.log(response.data);
      } catch (error) {
        logError(error, "handleGetInventory()");
        // msgError(error);
      } finally {
        dispatch(actions.setLoadingInventory(false));
      }
    },

  handleBulkAddInventory:
    (data, list) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAddingInventory(true));
      try {
        const bulkData = data?.product_varients?.map((item) => {
          const product = list.find((pdt) => pdt.id === item.product_id);
          return {
            product_varient: product?.product_variant,
            vendor: product?.vendor,
            stock: item?.stock,
          };
        });
        console.log({ product_varients: bulkData });
        await addBulkInventory({ product_varients: bulkData });
        message.success("Added inventories !");
        dispatch(actions.setViewBulkAddModal(false));
        dispatch(actions.handleGetInventoryList());
      } catch (error) {
        logError(error, "handleBulkAddInventory()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAddingInventory(false));
      }
    },

  handleAddInventory:
    (data, callback) =>
    async ({ dispatch }) => {
      console.log(data.mfg_date, "testttt");
      var datenow = moment(data.mfg_date).format("YYYY-MM-DD");
      data.mfg_date = datenow;
      var exp = moment(data.exp_date).format("YYYY-MM-DD");
      console.log(datenow, "date exp");
      data.exp_date = exp;
      dispatch(actions.setIsAddingInventory(true));
      try {
        console.log("final data", data);
        await addInventory(data);
        message.success("Added inventory !");
        callback && callback();
        window.location.replace("/inventory/list");
        dispatch(actions.setViewAddModal(false));
      } catch (error) {
        logError(error, "handleAddInventory()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAddingInventory(false));
      }
    },

  handleAddStock:
    (data, callback) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAddingInventory(true));
      dispatch(actions.setViewAddStockModal(false));
      try {
        console.log(data, "data");
        const formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        await addInventoryStock(formData);
        message.success("Added inventory !");
        callback && callback();
        dispatch(actions.setViewAddStockModal(false));
      } catch (error) {
        logError(error, "handleAddInventory()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAddingInventory(false));
      }
    },

  resetStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

export default actions;
