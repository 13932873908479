import { Button, Modal } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import { getJobcardStatus } from "./getJobcardStatus";

const JobFooter = ({ jobcard }) => {
  const [{ isUpdatingStatus }, { handleUpdateStatusJobcard }] =
    useJobcardStore();

  const jobcardStatus = {
    pending: { label: "Waiting to Accept", color: "clr-warn" },
    accepted: { label: "Collected by employees", color: "clr-warn" },
    ongoing: { label: "Ongoing", color: "clr-msg" },
    completed: { label: "Completed", color: "clr-msg" },
    delivered: { label: "Delivered", color: "clr-msg" },
    rejected: { label: "Declined", color: "clr-danger" },
  };

  return (
    <footer className="jobCardView-footer">
      <div className="jobCardView-footerInfo">
        <p
          className={
            jobcardStatus[getJobcardStatus(jobcard)]?.color
              ? jobcardStatus[getJobcardStatus(jobcard)]?.color
              : "clr-warn"
          }
        >
          <span>Status: </span>
          {jobcardStatus[getJobcardStatus(jobcard)]?.label
            ? jobcardStatus[getJobcardStatus(jobcard)]?.label
            : "Unknown"}
        </p>
        {jobcard?.status !== "delivered" && jobcard?.status !== "rejected" && (
          <Button
            onClick={() =>
              Modal.confirm({
                centered: true,
                title: "Decline Job",
                content: "Are you sure you want to decline the job ?",
                okText: "Decline",
                okButtonProps: { type: "danger" },
                onOk: () => handleUpdateStatusJobcard("decline", jobcard?.id),
              })
            }
          >
            Decline Job
          </Button>
        )}
      </div>

      <br />
      {console.log(`getJobcardStatus(jobcard)`, getJobcardStatus(jobcard))}
      {getJobcardStatus(jobcard) === "pending" ||
      getJobcardStatus(jobcard) === "accepted" ||
      getJobcardStatus(jobcard) === "ongoing" ||
      getJobcardStatus(jobcard) === "completed" ? (
        <Button
          onClick={() => handleUpdateStatusJobcard("deliver", jobcard?.id)}
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
          disabled={getJobcardStatus(jobcard) !== "completed"}
        >
          Deliver
        </Button>
      ) : jobcard?.status === "delivered" ? (
        <Button
          disabled
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
        >
          Delivered
        </Button>
      ) : jobcard?.status === "rejected" ? (
        <Button
          disabled
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
        >
          Declined
        </Button>
      ) : (
        <></>
      )}
    </footer>
  );
};

export default JobFooter;
