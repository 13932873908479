import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  addVendor,
  editVendor,
  getVendorList,
} from "../../../infrastructure/vendor";
import { convertObjectToFormData } from "../../Common/Utils/convertToFormData";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  setCurrentVendor:
    (currentVendor) =>
    ({ setState }) => {
      setState({ currentVendor });
    },

  getVendorList:
    () =>
    async ({ setState, dispatch }) => {
      try {
        const vendorsList = await getVendorList();
        if (vendorsList.data) {
          setState({ vendorsList: vendorsList?.data });
        } else {
          message.error("Could not fetch vendors details.");
        }
      } catch (error) {
        logError(error, " @ getVendorsList()");
        message.error(error.toString());
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  getVendor:
    (id, setLoading) =>
    async ({ setState }) => {
      try {
        const vendorsList = await getVendorList();
        if (vendorsList.data) {
          setState({
            currentVendor: vendorsList?.data?.filter(
              (vendor) => vendor?.id === id
            )[0],
          });
        } else {
          message.error("Could not fetch vendor details.");
        }
      } catch (error) {
        logError(error, " @ getVendor()");
        message.error(error.toString());
      } finally {
        setLoading(false);
      }
    },

  editVendor: (data, setLoading, id) => async () => {
    try {
      // const newData = mapper(data);
      const formData = convertObjectToFormData(data);
      const response = await editVendor(formData, id);
      if (response?.Statuscode === 6000) {
        message.success(response?.message);
      } else {
        logError(response, " @editVendor");
        message.error(response?.message);
      }
    } catch (error) {
      logError(error, " @ editVendor()");
      message.error(error.toString());
    } finally {
      setLoading(false);
    }
  },

  createVendor: (data, setLoading) => async () => {
    try {
      const formData = convertObjectToFormData(data);
      const response = await addVendor(formData);
      if (response?.Statuscode === 6000) {
        message.success(response?.message);
      } else {
        logError(response, " @createVendor");
        message.error(response?.message);
      }
    } catch (error) {
      logError(error, " @ createVendor()");
      message.error(error.toString());
    } finally {
      setLoading(false);
    }
  },
};

export default actions;
