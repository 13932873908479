import FeatherIconsReact from "feather-icons-react";
import logoWithTitle from "./Logo/logo_with_title.svg";
import logo from "./Logo/logo.svg";
import fallback from "./fallback.png";

const FeatherIcon = ({ icon, onClick, style, className, size = 18 }) => (
  <FeatherIconsReact
    icon={icon}
    className={className}
    onClick={onClick}
    size={size}
    style={style}
  />
);

export { logoWithTitle, logo, FeatherIcon, fallback };
