import React from "react";
import { Table, Space } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";

const TableData = ({ cart, user }) => {
  const [{ verfiedUser }, { deleteCart }] = useJobcardStore();
  console.log(cart, "cart values");
  const columns = [
    {
      title: "Item",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "service_price",
      key: "service_price",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            href="cartdelete"
            style={{ color: "#f05c2d" }}
            onClick={() => deleteCart(record?.id, verfiedUser?.customer_id)}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return <Table columns={columns} dataSource={cart} />;
};

export default TableData;
