import { Button, Input, Row } from "antd";
import { useState } from "react";
import { FeatherIcon } from "../../../../Common/Assets";
import { useJobcardStore } from "../../../../Stores/Jobcard";

const JobProducts = ({ products = [] }) => {
  const [serialNo, setserialNo] = useState("");
  const [, { handleSellJobcardProduct }] = useJobcardStore();
  const stockStatus = {
    outOfStock: "clr-danger",
    available: "clr-msg",
  };

  if (products?.length < 1) return <></>;
  console.log("product data", products);
  return (
    <div className="jobCardView-productsWrapper">
      <div className="jobCardView-products">
        {products.map((product, index) => (
          <div>
            <div key={index} className="jobCardView-product">
              <div className="jobCardView-productLeft">
                <h3>{product.product_name}</h3>
                <p>{product?.status}</p>
              </div>
              <span
                className={
                  stockStatus[product?.stock > 1 ? "available" : "outOfStock"]
                }
              >
                {product?.stock === null
                  ? ""
                  : product?.stock > 1
                  ? "Available"
                  : "Out of Stock"}
              </span>
            </div>
            <Row
              align="middle"
              justify="center"
              className="jobCardView-productsAdd"
            >
              <Input
                placeholder="Serial No."
                value={serialNo}
                onChange={(e) => setserialNo(e.target.value)}
              />
              <div className="jobCardView-productsAddIcon">
                <FeatherIcon icon="camera" />
              </div>
            </Row>
            <Button
              onClick={() =>
                handleSellJobcardProduct(serialNo, setserialNo, product.product)
              }
              type="primary"
              style={{ width: "100%" }}
            >
              Add
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobProducts;
