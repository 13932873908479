import "./index.scss";

import { Drawer } from "antd";
import { useState } from "react";
import sizeMe from "react-sizeme";
import FeatherIcon from "feather-icons-react";
import SideNav from "../../../Common/UI/SideNav";
import StackGrid from "react-stack-grid";
import VendorStackCard from "./VendorStackCard";
import { Link } from "react-router-dom";
import routes from "../../../Common/routes";

const VendorDash = ({ size: { width } }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className="vendorDashMain">
      <div className="vendorDashTopMenu">
        <FeatherIcon
          icon="align-left"
          onClick={() => setDrawerOpen(true)}
          className="vendorDashMenuIcon"
        />
        <Drawer
          placement="left"
          visible={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          closable
          closeIcon={
            <FeatherIcon className="vendorDashMenuIcon" icon="align-right" />
          }
        >
          <SideNav />
        </Drawer>
      </div>
      <div className="vendorDashContent">
        <div className="vendorContentTitle">Vendor Manage</div>
        <div className="vendoCardsStack">
          <StackGrid
            columnWidth={width < 425 ? "50%" : "33.33%"}
            gutterHeight={10}
            gutterWidth={5}
          >
            <Link to={routes.LIST_VENDOR("id")}>
              <VendorStackCard />
            </Link>
          </StackGrid>
        </div>
      </div>
    </div>
  );
};

export default sizeMe()(VendorDash);
