export const getJobcardStatus = (jobcard) => {
  if (jobcard?.status === "delivered") return "delivered";
  else if (jobcard?.status === "rejected") return "rejected";
  else if (jobcard?.services?.every((item) => item?.status === "pending"))
    return "pending";
  else if (jobcard?.services?.every((item) => item?.status === "accepted"))
    return "accepted";
  else if (jobcard?.services?.some((item) => item?.status === "ongoing"))
    return "ongoing";
  else if (jobcard?.services?.every((item) => item?.status === "completed"))
    return "completed";
  return "pending";
};
