import { Button, Col, Input, Row } from "antd";
import { Form } from "antd";
import React, { useState } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import { SearchOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import Loader from "../../../Common/UI/Loader";
import routes from "../../../Common/routes";
import { useHistory } from "react-router-dom";

const AddVehicle = ({ display, userData }) => {
  const [
    { verfiedUser, userBrands, brandModal },
    { getVehicleData, setbrandModal, addUserBrand },
  ] = useJobcardStore();
  const [form] = Form.useForm();
  const [id, setid] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();

  console.log(verfiedUser, "user data got2");
  return (
    <div
      className="jobCardView"
      style={{ display: display, flexDirection: "column" }}
    >
      <h3 className="jobCardView-title">User Details</h3>

      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Username </span>
        </p>
        <p>
          {userData?.name === undefined ? verfiedUser?.name : userData?.name}
        </p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Phone </span>
        </p>
        <p>
          {userData?.number === undefined
            ? verfiedUser?.phone
            : userData?.number}
        </p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Email </span>
        </p>
        <p>
          {userData?.email === undefined ? verfiedUser?.email : userData?.email}
        </p>
      </div>

      <h3 className="jobCardView-title">Enter Vehicle Details</h3>
      <Form
        requiredMark={false}
        className="inventoryAddFormWrapper"
        form={form}
        onFinish={(values) => {
          getVehicleData(
            values,
            verfiedUser?.id === undefined
              ? verfiedUser?.customer_id
              : verfiedUser?.id
          );
          form.resetFields();
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            name="rc"
            rules={[{ required: true, message: "Rc number is required" }]}
          >
            <Input placeholder="Vehicle registration number" />
          </Form.Item>
        </span>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </Form>
      <Modal
        title="Select a car"
        visible={brandModal}
        onOk={() => {
          addUserBrand(
            id,
            verfiedUser.id === undefined
              ? verfiedUser.customer_id
              : verfiedUser.id,
            userBrands?.register_number
          );
          setbrandModal(false);
          history.push(routes.MANUAL_FINAL);
        }}
        onCancel={() => setbrandModal(false)}
      >
        <div className="compare_search" style={{ height: "unset" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Input
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search car"
              autofocus
            />
            <SearchOutlined />
          </span>
        </div>
        {userBrands === null ? (
          <Loader tip="Loading" />
        ) : (
          <div
            className="Bottom vehicle_second_under"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "50px",
              flexWrap: "wrap",
              overflowY: "scroll",
              paddingRight: "24px",
              height: "40vh",
              width: "100%",
            }}
          >
            <Row>
              {userBrands?.data
                ?.filter((contact) =>
                  contact.model_name
                    .toLowerCase()
                    .match(searchQuery.toLowerCase())
                )
                .map((res) => (
                  <Col span={8}>
                    <span
                      onClick={() => setid(res.id) || console.log("clicked")}
                      className={
                        id === res.id
                          ? "signup_vehicle_wrap signup_border"
                          : "signup_vehicle_wrap"
                      }
                    >
                      <img alt="" src={res.vehicle_image}></img>
                      <p style={{ marginBottom: "0" }}>{res.model_name}</p>
                      <p style={{ paddingTop: "0" }}>{res.varient_name}</p>
                    </span>
                  </Col>
                ))}
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddVehicle;
