import "./index.scss";

import { useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useEffect, useState } from "react";
import { useVendorStore } from "../../../Stores/Vendor";
import Loader from "../../../Common/UI/Loader";
import DetailCard from "../../../Common/UI/DetailCard";

const VendorExpanded = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [{ currentVendor }, { getVendor, setCurrentVendor }] = useVendorStore();

  useEffect(() => {
    setCurrentVendor(null);
    const { vendorId } = props?.match?.params;
    if (vendorId) getVendor(vendorId, setLoading);
  }, [getVendor, props?.match?.params, setCurrentVendor]);

  if (loading) return <Loader tip="Loading vendor details..." />;

  return (
    <div className="vendorExpanded">
      <AppBar onBack={() => history.push(routes.LIST_VENDOR("category"))}>
        <div className="autoAppbar-title">
          <h3>{currentVendor?.name}</h3>
        </div>
      </AppBar>
      <DetailCard
        img=""
        type="circle"
        details={{
          Name: currentVendor?.name,
          Phone: currentVendor?.phone,
          Email: currentVendor?.email,
          City: currentVendor?.city,
          Address: currentVendor?.address,
          Pincode: currentVendor?.pincode,
        }}
      />
    </div>
  );
};

export default VendorExpanded;
