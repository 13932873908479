import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router";
import "./index.scss";
import { useEffect, useState } from "react";
import Loader from "../../../Common/UI/Loader";
import { useServiceStore } from "../../../Stores/Services";
import ServiceExpandedCard from "./ServiceExapandedCard";

const ServiceExpanded = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [{ currentCategory }, { setCurrentCategory, getCategory }] =
    useServiceStore();
  console.log(currentCategory);
  const history = useHistory(0);

  useEffect(() => {
    setCurrentCategory(null);
    const { serviceId } = match?.params;
    if (serviceId) getCategory(serviceId, setLoading);
  }, [getCategory, match?.params, setCurrentCategory]);

  if (loading) return <Loader tip="Getting service details" />;

  const { name } = currentCategory;

  return (
    <div className="serviceExpandedClass">
      <AppBar
        onBack={() => history.push(routes.SERVICES)}
        // dropDownMenu={
        //   <Menu>
        //     <Link to={routes.CREATE_EMPLOYEE}>
        //       <Menu.Item key="delete">Create</Menu.Item>
        //     </Link>
        //   </Menu>
        // }
      >
        <div className="autoAppbar-title">
          <h3>{name}</h3>
        </div>
      </AppBar>
      <div className="serviceContent">
        <ServiceExpandedCard {...currentCategory} />
        {/* <h3>Description</h3>
        <p>{currentCategory?.description}</p> */}
      </div>
    </div>
  );
};

export default ServiceExpanded;
