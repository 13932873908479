import "./index.scss";

import { Button, Form, Input } from "antd";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";

const AddForm = ({ id, varient }) => {
  const [
    { loadingInventoryList, isAddingInventory },
    { handleAddStock, setViewAddStockModal },
  ] = useInventoryStore();

  return (
    <Form
      className="inventoryAddFormWrapper"
      onFinish={(values) =>
        handleAddStock({
          ...values,
          product_variant: varient,
        }) || setViewAddStockModal(false)
      }
    >
      {loadingInventoryList ? (
        <Loader size="small" tip="Loading inventory data..." />
      ) : (
        <>
          <Form.Item label="Stock" name="stock">
            <Input />
          </Form.Item>
          <br />
          <Form.Item className="inventoryAddForm-submit">
            <Button
              loading={isAddingInventory}
              htmlType="submit"
              type="primary"
            >
              Add to Inventory
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default AddForm;
