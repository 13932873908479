import { get, post } from "../common/remote";

export const addInventory = (data) => {
  return post(`stores/add-to-store/`, data);
};
export const addInventoryStock = (data) => {
  return post(`stores/add-store-products/`, data);
};
export const addBulkInventory = (data) => {
  return post(`stores/product-varient/add/store/`, data);
};

export const viewInventory = (id) => {
  return get(`stores/product-varient/view/${id}`);
};

export const listInventory = () => {
  return get(`stores/store-products-view/`);
};

export const listVarients = () => {
  return get(`products/product-varient-all/view/`);
};

export const sellProduct = (serial, id) => {
  return post(`stores/sold/${id}/`, serial);
};
