import "./index.scss";

import { Button, Form, Input, InputNumber } from "antd";
import { useState } from "react";
import { useVendorStore } from "../../../Stores/Vendor";

const VendorForm = ({ currentVendor, purpose }) => {
  const [loading, setLoading] = useState(false);
  const [, { editVendor, createVendor }] = useVendorStore();

  return (
    <div className="vendorForm">
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={(data) => {
          setLoading(true);
          purpose === "edit"
            ? editVendor(data, setLoading, currentVendor?.id)
            : createVendor(data, setLoading);
        }}
      >
        {/* Item Name */}
        <Form.Item
          name="name"
          label="Vendor Name"
          initialValue={currentVendor && currentVendor?.name}
          rules={[{ required: true, message: "Item name is required" }]}
        >
          <Input placeholder="Vendor's name" />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item
          name="phone"
          label="Phone Number"
          initialValue={currentVendor && currentVendor?.phone}
          rules={[
            {
              required: true,
              message: "Phone number is required",
            },
          ]}
        >
          <InputNumber placeholder="Vendor's phone" />
        </Form.Item>

        {/* Email */}
        <Form.Item
          name="email"
          label="Email"
          initialValue={currentVendor && currentVendor?.email}
          rules={[
            {
              type: "email",
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input placeholder="Vendor's email" />
        </Form.Item>

        {/* Address */}
        <Form.Item
          name="address"
          label="Address"
          initialValue={currentVendor && currentVendor?.address}
          rules={[
            {
              required: true,
              message: "Address is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Vendor's address" />
        </Form.Item>

        <br />
        <Form.Item className="vendorForm-submit">
          <Button style={{ width: "100%" }} loading={loading} htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VendorForm;
