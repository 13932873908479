import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  getServiceReport,
  assignedEmployees,
} from "../../../infrastructure/dashboard";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  setCurrentEmployee:
    (currentEmployee) =>
    ({ setState }) => {
      setState({ currentEmployee });
    },

  getReport:
    (id, setLoading) =>
    async ({ setState }) => {
      try {
        const report = await getServiceReport();
        console.log(report, "report data");
        setState({ listData: report.data });
      } catch (error) {
        logError(error, " @ getEmployee()");
        message.error(error.toString());
      }
    },
  getPercent:
    () =>
    async ({ setState }) => {
      try {
        const data = await assignedEmployees();
        setState({ assigned: data });
      } catch (er) {
        console.log(er);
      }
    },
};

export default actions;
