import "./index.scss";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import { useEmployeeStore } from "../../../Stores/Employee";
import { useEffect, useState } from "react";
import Loader from "../../../Common/UI/Loader";
import ListCard from "../../../Common/UI/ListCard";
import ListCollection from "../../../Common/UI/ListCollection";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const EmployeeList = () => {
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [{ employeeList, loading }, { getEmployeeList }] = useEmployeeStore();

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);

  if (loading) return <Loader tip="Getting employee data..." />;

  return (
    <div className="employeeList">
      <AppBar
        onBack={() => history.push(routes.EMPLOYEE)}
        dropDownMenu={
          <Menu>
            <Link to={routes.CREATE_EMPLOYEE}>
              <Menu.Item key="delete">Create</Menu.Item>
            </Link>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Employee Management</h3>
          {searching ? (
            <div className="contactsTop contactsSearch">
              <input
                placeholder="Search contacts"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CloseOutlined
                onClick={() => {
                  setSearching(false);
                  setSearchQuery("");
                }}
              />
            </div>
          ) : (
            <div
              className="contactsTop"
              style={{ justifyContent: "space-between" }}
            >
              <SearchOutlined onClick={() => setSearching(true)} />
            </div>
          )}
        </div>
      </AppBar>
      <ListCollection>
        {employeeList
          .filter((employee) =>
            employee.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((res, key) => {
            return (
              <Link key={res.id} to={routes.EXPANDED_EMPLOYEE(res?.id)}>
                <ListCard
                  body={[res?.name, res?.phone, res?.emp_id]}
                  {...{ ...res, key }}
                />
              </Link>
            );
          })}
      </ListCollection>
    </div>
  );
};

export default EmployeeList;
