import "./index.scss";
import { useState, useEffect } from "react";
import { Drawer } from "antd";
import FeatherIcon from "feather-icons-react";
import SideNav from "../../Common/UI/SideNav";
import BasicChart from "./BasicChart";
import BasicLine from "./BasicLine/LineChart";
import RingProcess from "./RingProcess";
import { useDashboardStore } from "../../Stores/Dashboard";
import routes from "../../Common/routes";
import { Link } from "react-router-dom";

const Home = () => {
  const [{ listData, assigned }, { getReport, getPercent }] =
    useDashboardStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const [total, settotal] = useState(0);

  useEffect(() => {
    getReport();
    getPercent();
  }, [getReport, getPercent]);

  useEffect(() => {
    var total_sum = listData?.reduce((tot, arr) => {
      // return the sum with previous value
      return tot + arr?.total_job_amount;
      // set initial value as 0
    }, 0);
    settotal(total_sum);
  }, [listData]);

  console.log(assigned, "assigned");
  return (
    <div className="autoHome">
      <div className="autoHome-menuIcon">
        <FeatherIcon
          icon="align-left"
          className="autoMenuIcon"
          onClick={() => setMenuOpen((prev) => !prev)}
        />
        <Drawer
          placement="left"
          visible={menuOpen}
          onClose={() => setMenuOpen(false)}
          closable
          closeIcon={
            <FeatherIcon className="autoMenuIcon" icon="align-right" />
          }
        >
          <SideNav />
        </Drawer>
      </div>
      <h3 className="autoHome-header">Dashboard</h3>
      <div className="chart_wrap">
        <div style={{ width: "48%" }}>
          <div className="chart_part1">
            <RingProcess percent={assigned?.percentage} />
            <span>
              <h6>Service crew</h6>
              <h5>
                {assigned?.assigned_employees}/{assigned?.total_employees}
              </h5>
              <h6>assigned</h6>
            </span>
          </div>
          <div className="weekly_service line_chart">
            <h4>Top Service</h4>
            <span>
              <div style={{ paddingRight: "12px" }}>
                <h6>Service 1</h6>
                <h5>788</h5>
              </div>
              <BasicLine />
            </span>
            <span>
              <div style={{ paddingRight: "12px" }}>
                <h6>Service 2</h6>
                <h5>388</h5>
              </div>
              <BasicLine />
            </span>
            <span>
              <div style={{ paddingRight: "12px" }}>
                <h6>Service 3</h6>
                <h5>388</h5>
              </div>
              <BasicLine />
            </span>
            <span>
              <div style={{ paddingRight: "12px" }}>
                <h6>Service 4</h6>
                <h5>388</h5>
              </div>
              <BasicLine />
            </span>
            <span>
              <div style={{ paddingRight: "12px" }}>
                <h6>Service 5</h6>
                <h5>388</h5>
              </div>
              <BasicLine />
            </span>
          </div>
        </div>
        <div style={{ width: "48%" }}>
          <div className="weekly_service">
            <h4>Total Service</h4>
            <BasicChart />
          </div>
          <div className="weekly_service daily_report">
            <span className="daily_report_title">
              <h4>Daily Report</h4>
              <Link to={routes.DAILY_REPORT}>
                <FeatherIcon icon="arrow-right" />
              </Link>
            </span>

            <div>
              <span>
                <h6>Total jobs done</h6>
                <h5>{listData?.length}</h5>
              </span>
              <span>
                <h6>Total service cost</h6>
                <h5>{total}</h5>
              </span>
              <span>
                <h6>Total product cost</h6>
                <h5>32</h5>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
