import "./index.scss";

import moment from "moment";
import { Link } from "react-router-dom";
import routes from "../../../../Common/routes";

const JobListCard = ({ jobcard }) => {
  return (
    <Link to={routes.VIEW_JOB_CARD(jobcard?.id)}>
      <article className="jobListCard jobListServiceCard">
        <header>
          <p>JOB ID {jobcard?.job_id}</p>
          <p>
            {moment(jobcard?.date_added, "YYYY-MM-DD HH:mm").format(
              "DD.MM.YYYY HH:mm"
            )}
          </p>
        </header>
        <div className="jobListCard-body">
          <p>{`${jobcard?.brand} ${jobcard?.model}`}</p>
          <div className="jobListCard-bodyWrap">
            <h3>{jobcard?.vehicleNo}</h3>
          </div>
          <div className="jobListCard-bodyItems">
            {jobcard?.services?.length <= 0
              ? jobcard?.products?.map((item) => item?.product_name).join(", ")+"(Product purchase)"
              : jobcard?.services?.length <= 2
              ? jobcard?.services?.map((item) => item?.service_name).join(", ")
              : jobcard?.services
                  ?.map((item) => item?.service_name)
                  .slice(0, 2)
                  .join(", ") +
                ` ... + ${
                  isNaN(jobcard?.services?.length - 2)
                    ? 0
                    : jobcard?.services?.length - 2
                }`}
          </div>
        </div>
        <footer>
          <p
            className={
              jobcard?.services.length > 0 &&
              jobcard?.services
                ?.map((service) => service?.employee !== null)
                ?.every(Boolean)
                ? `clr-danger`
                : undefined
            }
          >
            Crew assigned
          </p>
          <p
            className={
              jobcard?.services.length > 0 &&
              jobcard?.services
                ?.map((service) => service?.bay !== null)
                ?.every(Boolean)
                ? `clr-danger`
                : undefined
            }
          >
            Bay assigned
          </p>
        </footer>
      </article>
    </Link>
  );
};

export default JobListCard;
