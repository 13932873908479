import { message } from "antd";
import { logError, msgError } from "../../../common/utils";
import { getBayList } from "../../../infrastructure/bay";
import { getEmployeeList } from "../../../infrastructure/employee";
import { listInventory, sellProduct } from "../../../infrastructure/inventory";
import {
  acceptJobcard,
  addProductToJobcard,
  assignJobcard,
  declineJobcard,
  deliverJobcard,
  listJobcards,
  viewJobcard,
  userVerify,
  userVehicles,
  createUser,
  userVehicleAdd,
  userBrandAdd,
  addTocart,
  viewCart,
  deleteFromCart,
  placeOrderStart,
  createManualJob,
  listReport,
  getServiceComb,
} from "../../../infrastructure/jobcard";
import routes from "../../Common/routes";

const actions = {
  setLoadingCardList:
    (loadingCardList) =>
    ({ setState }) => {
      setState({ loadingCardList });
    },

  setJobcardList:
    (jobcardList) =>
    ({ setState }) => {
      setState({ jobcardList });
    },

  setLoadingJobcard:
    (loadingJobcard) =>
    ({ setState }) => {
      setState({ loadingJobcard });
    },

  setJobcard:
    (jobcard) =>
    ({ setState }) => {
      setState({ jobcard });
    },

  setProductList:
    (productList) =>
    ({ setState }) => {
      setState({ productList });
    },

  setViewAssignModal:
    (viewAssignModal) =>
    ({ setState }) => {
      setState({ viewAssignModal });
    },

  setAddProductModal:
    (addProductModal) =>
    ({ setState }) => {
      setState({ addProductModal });
    },

  setLoadingAssignModal:
    (loadingAssignModal) =>
    ({ setState }) => {
      setState({ loadingAssignModal });
    },

  setLoadingReportModal:
    (loadingReportModal) =>
    ({ setState }) => {
      setState({ loadingReportModal });
    },

  setViewReportModal:
    (viewReportModal) =>
    ({ setState }) => {
      setState({ viewReportModal });
    },
  handleGetReportList:
    (reportList) =>
    ({ setState }) => {
      setState({ reportList });
    },

  setIsAssigning:
    (isAssigning) =>
    ({ setState }) => {
      setState({ isAssigning });
    },

  setIsAddingProduct:
    (isAddingProduct) =>
    ({ setState }) => {
      setState({ isAddingProduct });
    },

  setIsSelling:
    (isSelling) =>
    ({ setState }) => {
      setState({ isSelling });
    },

  setIsUpdatingStatus:
    (isUpdatingStatus) =>
    ({ setState }) => {
      setState({ isUpdatingStatus });
    },

  handleAddProductToService:
    (serviceId, serialNo, callback) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAddingProduct(true));
      try {
        const res = await addProductToJobcard(serviceId, {
          serial_number: serialNo,
        });
        if (res?.StatusCode === 6000) {
          await sellProduct(serialNo);
          callback && callback();
          dispatch(actions.setAddProductModal(null));
          message.success("Product added !");
        }
      } catch (error) {
        logError(error, "handleAddProductToService()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAddingProduct(false));
      }
    },

  handleGetJobcard:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingJobcard(true));
      try {
        const response = await viewJobcard(id);
        if (response?.StatusCode === 6000) {
          dispatch(actions.setJobcard(response.data));
          dispatch(actions.handleGetProductList());
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingJobcard(false));
      }
    },

  handleGetAssignModalData:
    (callback) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoadingAssignModal(true));
      try {
        const empResponse = await getEmployeeList();
        if (empResponse?.StatusCode === 6000) {
          setState({ assignEmployeeList: empResponse.data || [] });
        } else {
          message.warn(empResponse?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAssignModalData()");
        message.error("Error in fetching employee list");
      }
      try {
        const bayResponse = await getBayList();
        if (bayResponse?.StatusCode === 6000) {
          setState({ assignBayList: bayResponse.data || [] });
        } else {
          message.warn(bayResponse?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAssignModalData()");
        message.error("Error in fetching bay list");
      } finally {
        dispatch(actions.setLoadingAssignModal(false));
        callback && callback();
      }
    },

  handleSellJobcardProduct:
    (serialNo, setserialNo, id) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsSelling(true));
      try {
        const formData = new FormData();
        formData.append("serial_number", serialNo);
        await sellProduct(formData, id);
        setserialNo("");
      } catch (error) {
        logError(error);
        msgError(error);
      } finally {
        dispatch(actions.setIsSelling(false));
      }
    },

  handleAssignJobcard:
    (id, data, callback, jobId) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAssigning(true));
      try {
        const response = await assignJobcard(id, data);
        if (response?.StatusCode === 6000) {
          message.success("Jobcard assigned !");
          callback && callback();
          dispatch(actions.setViewAssignModal(false));
          dispatch(actions.handleGetJobcard(jobId));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleAssignJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAssigning(false));
      }
    },

  handleGetProductList:
    () =>
    async ({ dispatch }) => {
      try {
        const response = await listInventory();
        dispatch(actions.setProductList(response.data));
      } catch (error) {
        logError(error, "handleGetInventoryList()");
        msgError(error);
      }
    },

  handleUpdateStatusJobcard:
    (newStatus, id) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsUpdatingStatus(true));
      try {
        let response;
        if (newStatus === "accept") {
          response = await acceptJobcard(id);
        } else if (newStatus === "deliver") {
          response = await deliverJobcard(id);
        } else if (newStatus === "decline") {
          response = await declineJobcard(id);
        }
        if (response?.StatusCode === 6000) {
          message.success(`Jobcard ${newStatus}ed !`);
        } else if (response?.data?.message) {
          message.warn(response?.data?.message);
        } else {
          message.error("Some error occured");
        }
      } catch (error) {
        logError(error);
        msgError(error);
      } finally {
        dispatch(actions.setIsUpdatingStatus(false));
      }
    },

  handleGetAllJobcards:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingCardList(true));
      try {
        const response = await listJobcards();
        console.log(response, "report");
        if (response?.StatusCode === 6000) {
          dispatch(actions.setJobcardList(response.data || []));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAllJobcards()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingCardList(false));
      }
    },

  handleGetReport:
    (id) =>
    async ({ dispatch }) => {
      try {
        const response = await listReport(id);
        if (response?.StatusCode === 6000) {
          dispatch(actions.handleGetReportList(response?.data));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingReportModal(false));
      }
    },

  verifyUser:
    (values) =>
    async ({ setState }) => {
      console.log(values, "values");
      const formData = new FormData();
      formData.append("country", "IN");
      formData.append("phone", values);
      // setTimeout(function () {
      //   your_func();
      // }, 5000);
      if (values.length === 10) {
        var verify = await userVerify(formData);
        if (verify.data.name === null) {
          setState({ status: false });
        } else {
          setState({ status: true, verfiedUser: verify?.data });
          var res = await userVehicles(verify?.data?.customer_id);
          console.log(res, "user vehicles");
          setState({ userVehicles: res.data });
        }
        console.log(verify, "verify value");
      }
    },
  createProfile:
    (values) =>
    async ({ setState }) => {
      console.log(values, "values");
      const formData = new FormData();
      formData.append("country", "IN");
      formData.append("phone", values.number);
      formData.append("name", values.name);
      formData.append("email", values.email);
      var verify = await createUser(formData);
      console.log(verify, "new user");
      setState({ status: true, verfiedUser: verify?.data });
    },
  getVehicleData:
    (values, user_id) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setbrandModal(true));
      var vehicle = await userVehicleAdd(values.rc, user_id);
      setState({ userBrands: vehicle });
    },
  setbrandModal:
    (params) =>
    async ({ setState }) => {
      console.log(params, "modal");
      setState({ brandModal: params });
    },
  addUserBrand:
    (car_id, user_id, rc) =>
    async ({ setState }) => {
      const data = await userBrandAdd(car_id, user_id, rc);
      console.log(data, "car added");
      message.success("Car added");
    },
  addServiceCart:
    (user, service) =>
    async ({ setState, dispatch }) => {
      console.log(user, service, "id and params");
      var res = await getServiceComb(user, service);
      console.log("result of comb", res);
      const formData = new FormData();
      formData.append("service", res.data[0]?.id);
      await addTocart(user, formData);
      dispatch(actions.viewCart(user));
      viewCart(user);
    },
  viewCart:
    (user) =>
    async ({ setState }) => {
      var cart = await viewCart(user);
      console.log(cart);
      setState({ cart: cart.data });
    },
  startPayment:
    (user) =>
    async ({ setState }) => {
      var cart = await placeOrderStart(user);
      console.log(cart.data.total_amount, "place data");
      // setState({ cart: cart.total_amount });
      await createManualJob(user);
      message.success("Job Created");
      window.location.replace(routes.LIST_JOB_CARD);
    },
  deleteCart:
    (item, user) =>
    async ({ dispatch, setState }) => {
      var cart = await deleteFromCart(item);
      dispatch(actions.viewCart(user));
      viewCart(user);
      console.log(cart);
      dispatch(actions.viewCart);
    },
};

export default actions;
