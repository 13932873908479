import "./index.scss";

import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router";
import routes from "../../../Common/routes";
import { useEffect } from "react";
import { useServiceStore } from "../../../Stores/Services";
import Loader from "../../../Common/UI/Loader";
import ListCard from "../../../Common/UI/ListCard";
import config from "../../../../common/config";
import ListCollection from "../../../Common/UI/ListCollection";
import { Link } from "react-router-dom";

const ServicesList = () => {
  const history = useHistory();
  const [{ serviceCategory, loading }, { getServiceCategory }] =
    useServiceStore();

  useEffect(() => {
    getServiceCategory();
  }, [getServiceCategory]);

  if (loading) return <Loader tip="Getting service categories..." />;
  return (
    <div className="seviceClass">
      <AppBar onBack={() => history.push(routes.HOME)}>
        <div className="autoAppbar-title">
          <h3>Service Management</h3>
        </div>
      </AppBar>
      <ListCollection>
        {serviceCategory?.map((item, key) => (
          <Link to={routes.EXPANDED_SERVICE(item?.id)} key={key}>
            <ListCard
              body={[item?.service_id, item?.name, item?.category_name]}
              img={config.BASE_API + item?.image}
            />
          </Link>
        ))}
      </ListCollection>
    </div>
  );
};

export default ServicesList;
