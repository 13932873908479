import { message } from "antd";
import { logError } from "../../../common/utils";
import { getServiceCategory } from "../../../infrastructure/services";

const actions = {
  setServiceCategory:
    (serviceCategory) =>
    ({ setState }) => {
      setState({ serviceCategory });
    },

  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  getServiceCategory:
    () =>
    async ({ dispatch }) => {
      try {
        const { StatusCode, data } = await getServiceCategory();
        if (StatusCode === 6000 && data) {
          console.log("serivcesss", data);
          dispatch(actions.setServiceCategory(data));
        } else {
          throw new Error("Could not fetch service categories");
        }
      } catch (error) {
        logError(error, " @ getServiceCategory()");
        message.error(error.toString());
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  setCurrentCategory:
    (currentCategory) =>
    ({ setState }) => {
      setState({ currentCategory });
    },

  getCategory:
    (id, setLoading) =>
    async ({ dispatch }) => {
      try {
        const { StatusCode, data } = await getServiceCategory();
        if (StatusCode === 6000 && data) {
          dispatch(
            actions.setCurrentCategory(
              data.filter((category) => category?.id === id)?.[0]
            )
          );
        } else {
          throw new Error("Could not fetch service details.");
        }
      } catch (error) {
        logError(error, " @ getCategory()");
        message.error(error.toString());
      } finally {
        setLoading(false);
      }
    },
};

export default actions;
