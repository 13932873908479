import "./index.scss";

import { Empty, Menu, Spin, DatePicker } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import ListCard from "../../../Common/UI/ListCard";
import ListCollection from "../../../Common/UI/ListCollection";
import Loader from "../../../Common/UI/Loader";
import { useReportStore } from "../../../Stores/Reports";
import { downloadCSV } from "./downloadAsCSV";

const { RangePicker } = DatePicker;

const ViewReport = ({ match }) => {
  const [
    { loading, reportData },
    { handleGetReportData, handleGetTimelineReports },
  ] = useReportStore();
  const history = useHistory();

  const headerName = {
    storeIncome: "Store Income",
    serviceIncome: "Service Income",
    productIncome: "Product Income",
    stockIncome: "Stock Income",
  };

  useEffect(() => {
    handleGetReportData(match?.params?.reportType);
  }, [handleGetReportData, match?.params]);

  const handleTimelineChanges = (values) => {
    if (values.every((val) => val !== null)) {
      handleGetTimelineReports(match?.params?.reportType, values);
    }
  };

  return (
    <div>
      <AppBar
        onBack={() => history.push(routes.LIST_REPORTS)}
        dropDownMenu={
          <Menu>
            {loading ? (
              <Spin tip="Loading" />
            ) : (
              <Menu.Item
                key="export"
                onClick={() =>
                  downloadCSV(reportData, `${match?.params?.reportType}_report`)
                }
              >
                Export as CSV
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>
            {headerName[match?.params?.reportType]
              ? headerName[match?.params?.reportType]
              : "Unknown"}
          </h3>
        </div>
      </AppBar>
      <div className="viewReportContainer">
        <RangePicker
          onChange={handleTimelineChanges}
          className="viewReportContainer-dateInput"
        />
        {loading ? (
          <Loader tip="Loading reports..." />
        ) : reportData?.length === 0 ? (
          <Empty description="No data" />
        ) : (
          <ListCollection>
            {reportData.map((report) =>
              match?.params?.reportType === "stockIncome" ? (
                <ListCard
                  header={[
                    report?.product_category,
                    report?.product_subcategory,
                  ]}
                  body={[report?.name]}
                  footer={[`${report?.stock} items`, ``]}
                />
              ) : match?.params?.reportType === "productIncome" ? (
                <ListCard
                  header={[
                    report?.product_category,
                    report?.product_subcategory,
                  ]}
                  body={[report?.name]}
                  footer={[
                    `${report?.no_of_orders} orders`,
                    `₹ ${report?.revenue}`,
                  ]}
                />
              ) : match?.params?.reportType === "serviceIncome" ? (
                <ListCard
                  header={[report?.service_id, ""]}
                  body={[report?.name]}
                  footer={[
                    `${report?.no_of_orders} orders`,
                    `₹ ${report?.revenue}`,
                  ]}
                />
              ) : (
                <Empty description="No data" />
              )
            )}
          </ListCollection>
        )}
      </div>
    </div>
  );
};

export default ViewReport;
