import { message } from "antd";

export const downloadCSV = (data = [], name) => {
  if (data?.length >= 1) {
    const csvFormatData = reportsToCsvFormat(data);
    const csvFile =
      "data:text/csv;charset=utf-8," +
      csvFormatData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvFile);
    const downloader = document.createElement("a");
    downloader.href = encodedUri;
    downloader.target = "__blank";
    downloader.download = `${name}.csv`;
    downloader.click();
  } else {
    message.info("No data to export !");
  }
};

export const reportsToCsvFormat = (data = []) => {
  let csvFormatData = [];
  csvFormatData.push(Object.keys(data[0]));
  data?.map((item) => csvFormatData.push(Object.values(item)));
  return csvFormatData;
};
