import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { Table, Menu } from "antd";
import { useDashboardStore } from "../../../Stores/Dashboard";
import { useEffect } from "react";

const InventoryView = ({ match }) => {
  const history = useHistory();
  const [{ listData }, { getReport }] = useDashboardStore();
  console.log(listData, "List data");
  useEffect(() => {
    getReport();
  }, [getReport]);

  const columns = [
    {
      title: "Vehicle",
      dataIndex: "vehicle_no",
      key: "vehicle_no",
      render: (text) =>text,
    },
    {
      title: "Job Amount",
      dataIndex: "total_job_amount",
      key: "total_job_amount",
    },

    {
      title: "Service ",
      dataIndex: "services",
      key: "services",
      render: (text) => (
        <ul style={{ listStyleType: "none" }}>
          {text?.map((res) => (
            <li>{res?.service_name}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Service price ",
      dataIndex: "services",
      key: "services",
      render: (text) => (
        <ul style={{ listStyleType: "none" }}>
          {text?.map((res) => (
            <li>{res?.total_price}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <div className="inventoryViewContainer">
      <AppBar
        onBack={() => history.push(routes.HOME)}
        dropDownMenu={
          <Menu>
            <Menu.Item key="add">Download</Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Daily report</h3>
        </div>
      </AppBar>
      <div id="daily_report_table">
        <Table columns={columns} dataSource={listData} />
      </div>
    </div>
  );
};

export default InventoryView;
