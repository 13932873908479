import { Button, Input, Form } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";

const AddProductModal = ({ form, id }) => {
  const [{ isAddingProduct }, { handleAddProductToService }] =
    useJobcardStore();
  return (
    <Form
      form={form}
      requiredMark={false}
      layout="vertical"
      className="bayAssignForm"
      onFinish={(values) =>
        handleAddProductToService(id, values?.serialNo, () => {
          form.resetFields();
        })
      }
    >
      <Form.Item
        name="serialNo"
        label="Product Serial No."
        rules={[{ required: true, message: "Please enter a serial no" }]}
      >
        <Input placeholder="Serial No." />
      </Form.Item>
      <br />
      <Form.Item>
        <Button loading={isAddingProduct} type="primary" htmlType="submit">
          Assign
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddProductModal;
