import "./index.scss";
import Avatar from "antd/lib/avatar/avatar";
import { fallback, FeatherIcon } from "../../Assets";
import { Row } from "antd";

const DetailCard = ({ type = "box", img, name, desc, details = {} }) => {
  return (
    <div className="autoDetailCard">
      {type === "box" ? (
        <div className="autoDetailCard-top">
          <img
            src={img}
            alt={name}
            onError={({ target }) => (target.src = fallback)}
          />
          <div className="autoDetailCard-topRight">
            <h3>{name}</h3>
            <span>{desc}</span>
          </div>
        </div>
      ) : (
        <Row style={{ marginTop: 30 }}>
          <Avatar
            style={{ margin: "auto" }}
            src={img}
            icon={<FeatherIcon icon="user" size={100} />}
            size={100}
          />
        </Row>
      )}
      <div className="autoDetailCard-infoList">
        {Object.keys(details)?.length > 0 &&
          Object.keys(details)?.map((key, index) => (
            <div key={index} className="autoDetailCard-info">
              <p>{key}</p>
              <p>{details[key]}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DetailCard;
