import { Button, Select } from "antd";
import React, { useEffect } from "react";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import JobAppBar from "../../Common/JobAppBar";
import { useHistory } from "react-router-dom";
import { useServiceStore } from "../../../../Stores/Services";
import TableCart from "./TableData";
import Loader from "../../../../Common/UI/Loader";

const ServiceSelect = () => {
  const [{ verfiedUser, cart }, { viewCart, addServiceCart, startPayment }] =
    useJobcardStore();
  const [{ serviceCategory }, { getServiceCategory }] = useServiceStore();
  const history = useHistory();
  const { Option } = Select;

  useEffect(() => {
    getServiceCategory();
    viewCart(verfiedUser?.id);
  }, [getServiceCategory, viewCart, verfiedUser]);

  const onChange = (value) => {
    var id;
    if (verfiedUser?.customer_id === undefined) {
      id = verfiedUser?.id;
    } else {
      id = verfiedUser?.customer_id;
    }
    console.log("vale of id", id);
    addServiceCart(id, value);
    console.log(verfiedUser, value, "values now");
    viewCart(id);
  };

  console.log(verfiedUser, "verified user value");
  return (
    <div
      className="jobCardView"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <JobAppBar onBack={() => history.goBack()} title="Manual jobcard" />
      <h3 className="jobCardView-title">User Details</h3>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Username </span>
        </p>
        <p>{verfiedUser?.name}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Email </span>
        </p>
        <p>{verfiedUser?.email}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Phone </span>
        </p>
        <p>{verfiedUser?.phone}</p>
      </div>
      <h3 className="jobCardView-title">Select services</h3>
      <Select
        showSearch
        placeholder="Select a service"
        optionFilterProp="children"
        onChange={onChange}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {serviceCategory !== null ? (
          serviceCategory?.map((item, key) => (
            <Option value={item?.id}> {item?.name}</Option>
          ))
        ) : (
          <Loader tip="Loading services" />
        )}
      </Select>
      <div style={{ paddingTop: "34px" }} id="cart_table">
        {" "}
        <TableCart
          cart={cart}
          user={
            verfiedUser?.customer_id === undefined
              ? verfiedUser?.id
              : verfiedUser?.customer_id
          }
        />
      </div>
      <div className="cart_jobcart">
        <Button
          type="primary"
          onClick={() =>
            startPayment(
              verfiedUser?.customer_id === undefined
                ? verfiedUser?.id
                : verfiedUser?.customer_id
            )
          }
        >
          Create Jobcard
        </Button>
      </div>
    </div>
  );
};

export default ServiceSelect;
