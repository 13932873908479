import { get, post, del } from "../common/remote/base_api";

export const listJobcards = () => {
  return get(`jobcards/list-all/`);
};

export const listReport = (id) => {
  return get(`reports/store-jobwise-report/${id}/`);
};

export const listJobcardsByStatus = (status) => {
  return get(`jobcards/list-status/?status=${status}`);
};

export const listAvailableEmployee = () => {
  return get(`jobcards/list-all-employee/`);
};

export const addProductToJobcard = (id, data) => {
  return post(`jobcards/assign-products-service/${id}/`, data);
};

export const viewJobcard = (id) => {
  return get(`jobcards/view-detail/${id}`);
};

export const acceptJobcard = (id) => {
  return post(`jobcards/accepted/${id}`);
};

export const assignJobcard = (id, data) => {
  return post(`jobcards/assign/${id}/`, data);
};

export const declineJobcard = (id) => {
  return get(`jobcards/rejected/${id}`);
};

export const deliverJobcard = (id) => {
  return post(`jobcards/delivered/${id}/`);
};
export const userVerify = (params) => {
  return post(`users/verify-customer/`, params);
};
export const createUser = (params) => {
  return post(`users/manual-create/customer/`, params);
};
export const userVehicles = (params) => {
  return get(`vehicles/manual-jobcard/vehicle-list/${params}`);
};
export const userVehicleAdd = (rc, user) => {
  console.log(rc);
  return post(
    `vehicles/manual-add-cutomer-vehicle/${user}/?register_number=${rc}`
  );
};
export const userBrandAdd = (car, user, rc) => {
  return post(
    `/vehicles/manual-add-cutomer-vehicle-varient/${user}/?id=${car}&register_number=${rc}`
  );
};
export const addTocart = (id, params) => {
  return post(`carts/manual-jobcard/add-services-to-cart/${id}/`, params);
};
export const viewCart = (params) => {
  return get(`carts/manual-jobcard/view-cart/${params}/`);
};
export const deleteFromCart = (params) => {
  return del(`carts/manual-jobcard/remove-cart/${params}/`);
};

export const placeOrderStart = (id) => {
  return post(`orders/manual-jobcard/ordering/${id}/`);
};

export const createManualJob = (id) => {
  return post(`orders/manual-job-card/place-order/${id}/`);
};
export const getServiceComb = (id, service) => {
  console.log("service values ", service);
  return get(
    `stores/manual-jobcard/customer-service-combination-view/${id}/?service=${service}`
  );
};
